import { CouponCodeValidationResponse } from "@treefort/api-spec"
import strictEncodeUriComponent from "@treefort/lib/strict-encode-uri-component"

import api from "./api"

export async function validateCouponCode(couponCode: string) {
  const res = await api.get<CouponCodeValidationResponse>(
    `/coupon-codes/validate/${strictEncodeUriComponent(couponCode)}`,
  )
  return res.data
}

export async function redeemCouponCode({
  couponCodeId,
}: {
  couponCodeId: number
}) {
  return api.post(`/coupon-codes/${couponCodeId}/redeem`)
}
