import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

import { useAuth } from "@treefort/lib/auth-provider"
import { DisplayableError } from "@treefort/lib/displayable-error"
import Settings from "@treefort/lib/settings"

import { AsyncViewOfflineProvider } from "../../components/async-view-offline-provider"
import CallToSignUp, {
  CallToSignUpState,
} from "../../components/call-to-sign-up"
import {
  LibraryEmptyMessage,
  getLibraryEmptyMessageHeight,
} from "../../components/library/empty-message"
import {
  getLibraryListItemHeight,
  getLibraryListItemPaddingVertical,
  LibraryListItem,
} from "../../components/library/list-item"
import {
  getLibraryOfflineMessageHeight,
  LibraryOfflineMessage,
} from "../../components/library/offline-message"
import {
  getLibraryTabsHeight,
  LIBRARY_TABS,
  LibraryTab,
  LibraryTabs,
} from "../../components/library/tabs"
import { ListViewRef } from "../../components/list-view"
import { useTokens } from "../../components/tokens-provider"
import config from "../../config"
import { useActiveProfileId } from "../../hooks/use-active-profile-id"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import { useEntitlements } from "../../hooks/use-entitlements"
import { useIsFocused } from "../../hooks/use-is-focused"
import { useOfflineState } from "../../hooks/use-offline-state"
import useQueryKey from "../../hooks/use-query-key"
import { useRouteParams } from "../../hooks/use-route-params"
import useSafeAreaInsetAppHeader from "../../hooks/use-safe-area-inset-app-header"
import authenticator from "../../lib/authenticator"
import { stopAudioPlayerIfCurrent } from "../../lib/content-audio"
import DownloadItem from "../../lib/download-item"
import {
  LibraryItem,
  getLibraryItemKey,
  sortLibraryItems,
} from "../../lib/library"
import { logError } from "../../lib/logging"
import { debug as appDebug } from "../../lib/logging"
import {
  getProgressItemFromSettingValue,
  ProgressItemData,
  PlayableProgressItem,
  ReadableProgressItem,
  getProgressItemFromConsumableContent,
} from "../../lib/progress-item"
import { queryClient } from "../../lib/query-client"
import { progressStore } from "../../watermelon/stores/progress"
import { syncManager } from "../../watermelon/sync"
import PageLayout from "../layouts/page"

const debug = appDebug.extend("library")

// This represents an item to display in the virtualized list view. This could
// be a library item or any other bit of UI we need to stack in the library
// page.
type ListViewItem =
  | {
      type: "libraryItem"
      libraryItem: LibraryItem
    }
  | { type: "spacer"; size: number }
  | { type: "offlineMessage" }
  | { type: "emptyMessage" }

function getLibraryItemListViewItem(libraryItem: LibraryItem): ListViewItem {
  return { type: "libraryItem", libraryItem }
}

function getSpacerListViewItem(size: number): ListViewItem {
  return { type: "spacer", size }
}

function getOfflineMessageListViewItem(): ListViewItem {
  return { type: "offlineMessage" }
}

function getEmptyMessageListViewItem(): ListViewItem {
  return { type: "emptyMessage" }
}

function isLibraryTab(tab: string | undefined): tab is LibraryTab {
  return LIBRARY_TABS.some((t) => t === tab)
}

const noop = () => {}

export function LibraryScreen(): JSX.Element {
  /**
   * STATE
   */

  const auth = useAuth()
  const profileId = useActiveProfileId()
  const { tokens } = useTokens()
  const focused = useIsFocused()
  const focusedPrev = useRef<boolean>(focused)
  const [offline, refreshOfflineState] = useOfflineState()
  const { t } = useTranslation()
  const appHeaderSafeAreaInset = useSafeAreaInsetAppHeader()
  const authenticated = Boolean(auth.user)
  const allowCallToSignUp = auth.initialized && !authenticated
  const [callToSignUpState, setCallToSignUpState] = useState<CallToSignUpState>(
    allowCallToSignUp ? "loading" : "hidden",
  )
  const listView = useRef<ListViewRef<ListViewItem>>(null)

  // Store the selected tab in route params for deep linkability. IMPORTANT:
  // Deep linking to a library tab from another page only works on the web! A
  // react-navigation bug causes the tab route parameter to disappear when
  // navigating from another route to the library.
  const [routeParams, setRouteParams] = useRouteParams()
  const tab = isLibraryTab(routeParams.tab)
    ? routeParams.tab
    : offline
      ? "downloaded"
      : LIBRARY_TABS[0]
  const setTab = useCallback(
    (tab: LibraryTab) => setRouteParams({ tab }),
    [setRouteParams],
  )

  /**
   * QUERIES
   */

  // Fetch progress data.
  const progressQueryKey = useQueryKey(["progress", profileId])
  const progressQuery = useQuery(
    progressQueryKey,
    async () => {
      debug("Executing progress query")
      const currentUserId = authenticator.getUser()?.id

      if (!currentUserId) {
        debug("currentUserId is not provided; returning empty array")
        return []
      }

      if (!syncManager.hasSynced(currentUserId)) {
        debug("Awaiting immediate sync because we have not synced before", {
          currentUserId,
        })
        await syncManager.requestSync({ syncType: "immediate" })
      } else {
        syncManager.requestSync({ syncType: "user-initiated" })
      }

      const progresses =
        await progressStore.getAllByCurrentUser<ProgressItemData>({ profileId })
      debug("Fetched progress items", { progresses })

      debug("progressQuery")
      const result = progresses.flatMap((progress) => {
        if (!progress) {
          return []
        }

        const progressItem = getProgressItemFromSettingValue({
          value: progress.value,
          profileId,
        })

        if (!progressItem) {
          return []
        }

        return {
          progressItem,
          progressUpdatedAt: progress.timestamp,
        }
      })
      debug("progressQuery")
      return result
    },
    { enabled: authenticated && focused },
  )

  // Keep the progress query up to date
  const refetchProgressQuery = progressQuery.refetch
  useEffect(() => {
    const subscription = progressStore.observeCount().subscribe(() => {
      debug("Progress count changed; refetching progress")
      refetchProgressQuery()
    })
    return () => subscription.unsubscribe()
  }, [refetchProgressQuery])

  // Fetch the user's entitlements
  const entitlementsQuery = useEntitlements({
    enabled: authenticated && focused && !offline,
  })
  const refetchEntitlementsQuery = entitlementsQuery.refetch

  // Extract content and progress data for all downloads out of the local store
  const downloadsQueryEnabled = authenticated && focused
  const downloadsQueryKey = useQueryKey(["downloads", profileId])
  const downloadsQuery = useQuery(
    downloadsQueryKey,
    async () => {
      const downloadItems = await DownloadItem.getAll()
      return Promise.all(
        downloadItems.map((downloadItem) => {
          const consumableContent = downloadItem.getConsumableContent()
          const progressItem = getProgressItemFromConsumableContent({
            consumableContent,
            profileId,
          })
          return {
            downloadItem,
            progressItem,
          }
        }),
      )
    },
    { enabled: downloadsQueryEnabled },
  )
  const refetchDownloadQuery = downloadsQueryEnabled
    ? downloadsQuery.refetch
    : noop

  /**
   * LIST VIEW ITEMS
   */

  // This merges progress items loaded from Watermelon, entitlements loaded from
  // the API, and download items loaded from local storage. The goal with this
  // logic is to show the most up-to-date data that we have as soon as we can.
  const allItems = useMemo<LibraryItem[]>(() => {
    const progressQueryData = progressQuery.data || []
    const entitlementsQueryData = entitlementsQuery.data || []
    const downloadsQueryData = downloadsQuery.data || []

    // This maps are an optimization that allow fast deduplicating of data in
    // the library query vs the downloads query vs the entitlements query.
    const itemIndexByContentId = new Map<number, number>()
    const itemIndexByProgressItemKey = new Map<string, number>()
    const result: LibraryItem[] = []

    // Start the list by adding items from the library query. If the query
    // hasn't loaded yet this will be a noop and the following logic will still
    // add items from the local downloads store.
    progressQueryData.forEach((item, index) => {
      const contentId = item.progressItem.getData().contentId
      const progressItemKey = item.progressItem.getKey()

      // Update the maps for use when processing the downloads query results and
      // the entitlements query results below.
      itemIndexByContentId.set(contentId, index)
      itemIndexByProgressItemKey.set(progressItemKey, index)

      result.push({
        contentId,
        progressItem: item.progressItem,
        progressUpdatedAt: item.progressUpdatedAt,
        downloadItem: undefined,
        entitled: false,
      })
    })

    // Add items from the download query. If an item already exists in the
    // array, update it, otherwise add a new one.
    downloadsQueryData.forEach((item) => {
      const progressItemKey = item.progressItem.getKey()
      const index = itemIndexByProgressItemKey.get(progressItemKey)
      if (index === undefined) {
        const contentId = item.progressItem.getData().contentId

        // Update the map for use when processing the entitlements query results
        // below.
        itemIndexByContentId.set(contentId, result.length)

        result.push({
          contentId,
          progressItem: item.progressItem,
          progressUpdatedAt: undefined,
          downloadItem: item.downloadItem,
          entitled: false,
        })
      } else {
        result[index].downloadItem = item.downloadItem
      }
    })

    // Add entitled items if they didn't show up anywhere in progress or
    // download data
    entitlementsQueryData.forEach((item) => {
      const contentId = item.contentId
      const index = itemIndexByContentId.get(contentId)
      if (
        index === undefined ||
        result[index].progressItem?.getData().contentType === "podcast"
      ) {
        result.push({
          contentId,
          progressItem: undefined,
          progressUpdatedAt: undefined,
          downloadItem: undefined,
          entitled: true,
        })
      } else {
        result[index].entitled = true
      }
    })

    return result
  }, [progressQuery.data, downloadsQuery.data, entitlementsQuery.data])

  // Filter the items down to whatever should show up in the currently selected
  // tab.
  const filteredItems = useMemo(() => {
    return allItems.filter((item) => {
      switch (tab) {
        case "inProgress": {
          if (item.progressItem instanceof PlayableProgressItem) {
            const progress = item.progressItem.getProgress()
            return (
              progress.finished === false &&
              (progress.index > 0 || progress.position > 0)
            )
          } else if (item.progressItem instanceof ReadableProgressItem) {
            const progress = item.progressItem.getProgress()
            return progress.finished === false && progress.percent > 0
          } else {
            return false
          }
        }
        case "notStarted": {
          if (!item.progressItem) {
            return false
          }
          if (item.progressItem instanceof PlayableProgressItem) {
            const progress = item.progressItem?.getProgress()
            return (
              !progress.finished &&
              progress.index === 0 &&
              progress.position === 0
            )
          } else if (item.progressItem instanceof ReadableProgressItem) {
            const progress = item.progressItem.getProgress()
            return !progress.finished && progress.percent === 0
          } else {
            return false
          }
        }
        case "finished":
          return item.progressItem?.getProgress().finished === true
        case "downloaded":
          return Boolean(item.downloadItem)
        case "purchased":
          return Boolean(item.entitled)
      }
    })
  }, [allItems, tab])

  // Sort items first by most recently played and second by most recently
  // downloaded.
  const filteredAndSortedItems = useMemo(() => {
    return sortLibraryItems(filteredItems)
  }, [filteredItems])

  // Calculate various dimensions (important for virtualized lists)
  const libraryItemHeight = getLibraryListItemHeight(tokens)
  const libraryItemPaddingVertical = getLibraryListItemPaddingVertical(tokens)
  const emptyMessageHeight = getLibraryEmptyMessageHeight(tokens)
  const offlineMessageHeight = useMemo(
    // Memoize since this one's a bit more expensive
    () => getLibraryOfflineMessageHeight(tokens),
    [tokens],
  )
  const libraryTabsHeight = useMemo(
    // Memoize since this one's a bit more expensive
    () => (authenticated ? getLibraryTabsHeight(tokens) : 0),
    [tokens, authenticated],
  )
  const headerHeight = appHeaderSafeAreaInset + libraryTabsHeight

  // Add spacers above/below the library items (if we're rendering any). This
  // may seem a bit complex just for some padding, but its necessary when
  // working with a virtualized layout.
  const listViewItems = useMemo(
    () =>
      allowCallToSignUp && callToSignUpState === "visible"
        ? []
        : progressQuery.isSuccess &&
            downloadsQuery.isSuccess &&
            filteredAndSortedItems.length === 0 &&
            !offline
          ? [
              getSpacerListViewItem(headerHeight + libraryItemPaddingVertical),
              getEmptyMessageListViewItem(),
            ]
          : [
              getSpacerListViewItem(headerHeight + libraryItemPaddingVertical),
              ...(offline ? [getOfflineMessageListViewItem()] : []),
              ...filteredAndSortedItems.map(getLibraryItemListViewItem),
              getSpacerListViewItem(libraryItemPaddingVertical),
            ],
    [
      allowCallToSignUp,
      callToSignUpState,
      progressQuery.isSuccess,
      downloadsQuery.isSuccess,
      filteredAndSortedItems,
      offline,
      headerHeight,
      libraryItemPaddingVertical,
    ],
  )

  /**
   * EVENT HANDLERS
   */

  const onChangeTab = useCallback(
    (tab: LibraryTab) => {
      listView.current?.scrollToOffset(0)
      setTab(tab)
      refreshOfflineState()
      refetchDownloadQuery()
      refetchProgressQuery()
    },
    [setTab, refreshOfflineState, refetchDownloadQuery, refetchProgressQuery],
  )

  // Mark an item as finished and optimistically update the library query data.
  const onPressMarkItemFinished = useCallback(
    async (libraryItem: LibraryItem) => {
      try {
        if (!libraryItem.progressItem) {
          throw new Error(
            `[Library] Missing progress item for content #${libraryItem.contentId}`,
          )
        }

        const libraryItemKey = getLibraryItemKey(libraryItem)
        await stopAudioPlayerIfCurrent(libraryItem.progressItem)
        libraryItem.progressItem.updateProgress({ finished: true })
        libraryItem.progressItem.saveLocalAndRemote()
        queryClient.setQueryData<LibraryItem[]>(
          progressQueryKey,
          (libraryItems) => {
            if (libraryItems) {
              const index = libraryItems.findIndex(
                (libraryItem) =>
                  getLibraryItemKey(libraryItem) === libraryItemKey,
              )
              if (index > -1) {
                return [
                  ...libraryItems.slice(0, index),
                  {
                    ...libraryItems[index],
                    progressItem: libraryItem.progressItem,
                    progressUpdatedAt: Settings.getUnixTimestampSeconds(),
                  },
                  ...libraryItems.slice(index + 1),
                ]
              }
            }
            return libraryItems || []
          },
        )
      } catch (error) {
        logError(
          new DisplayableError(
            t(
              "An error occurred marking the item as finished. Please try again.",
            ),
            error,
          ),
        )
      }
    },
    [progressQueryKey, t],
  )

  // Remove an item from the library by deleting its setting, optimistically
  // updating the library query data, and clearing the audio player if the item
  // is loaded into it.
  const onPressRemoveItem = useCallback(
    async (libraryItem: LibraryItem) => {
      try {
        if (!libraryItem.progressItem) {
          throw new Error(
            `[Library] Missing progress item for content #${libraryItem.contentId}`,
          )
        }

        // Optimistically update the library query
        const libraryItemKey = getLibraryItemKey(libraryItem)
        queryClient.setQueryData<LibraryItem[]>(
          progressQueryKey,
          (libraryItems) =>
            libraryItems?.filter(
              (libraryItem) =>
                getLibraryItemKey(libraryItem) !== libraryItemKey,
            ) || [],
        )

        // Optimistically update the downloads query
        if (libraryItem.downloadItem) {
          const downloadItemKey = libraryItem.downloadItem.getKey()
          queryClient.setQueriesData<LibraryItem[]>(
            downloadsQueryKey,
            (libraryItems) =>
              libraryItems?.filter(
                (libraryItem) =>
                  !libraryItem.downloadItem ||
                  libraryItem.downloadItem.getKey() !== downloadItemKey,
              ) || [],
          )
        }

        // Clear the audio player if the item we're removing is loaded
        await stopAudioPlayerIfCurrent(libraryItem.progressItem)

        // Clear out the store and delete any downloaded content
        await Promise.all([
          libraryItem.progressItem.clearLocalAndRemote(),
          libraryItem.downloadItem?.deleteDownload(),
        ])
      } catch (error) {
        logError(
          new DisplayableError(
            t("An error occurred while removing item. Please try again."),
            error,
          ),
        )
      }
    },
    [progressQueryKey, downloadsQueryKey, t],
  )

  /**
   * OTHER HOOKS
   */

  const asyncViewProps = useAsyncViewPropsForQueries(
    // Never hold the downloads tab up for anything but downloads. We don't want
    // network or sync issues to get in the way of accessing offline content.
    tab === "downloaded"
      ? [downloadsQuery]
      : tab === "purchased"
        ? [downloadsQuery, progressQuery, entitlementsQuery]
        : [downloadsQuery, progressQuery],
    {
      // Show a loading spinner any time we're refetching items - updating
      // virtualized lists in place, especially while the user is scrolling,
      // results in a poor UX.
      disableBackgroundRefetch: true,
      forceLoading: allowCallToSignUp && callToSignUpState === "loading",
    },
  )

  // Check again to see if we're still offline every time the user comes back to
  // the page.
  useEffect(() => {
    const focusedChange = focused !== focusedPrev.current
    focusedPrev.current = focused
    if (focusedChange) {
      // Refetch everything when the page comes into focus to keep the data
      // fresh
      if (focused) {
        refreshOfflineState()
        refetchDownloadQuery()
        refetchProgressQuery()
        refetchEntitlementsQuery()
      }
      // Reset the queries when the page loses focus so that it's already in a
      // loading state when the page comes back into focus.
      else {
        queryClient.resetQueries({ queryKey: progressQueryKey })
        queryClient.resetQueries({ queryKey: downloadsQueryKey })
      }
    }
  }, [
    tab,
    focused,
    refreshOfflineState,
    refetchProgressQuery,
    refetchDownloadQuery,
    refetchEntitlementsQuery,
    progressQueryKey,
    downloadsQueryKey,
  ])

  /**
   * RENDER
   */

  return (
    <AsyncViewOfflineProvider
      {...asyncViewProps}
      offlineStateDisabled={config.DOWNLOADS_SUPPORTED && authenticated}
    >
      <PageLayout
        headerDropShadow={!authenticated}
        headerChildren={
          authenticated ? (
            <LibraryTabs currentTab={tab} onChange={onChangeTab} />
          ) : undefined
        }
        headerChildrenHeight={libraryTabsHeight}
        listViewProps={
          // Only render the list view when we're in a success state. This
          // ensures that the scroll is reset every time we switch tabs (and
          // show a loading state) and prevents performance issues related to
          // trying to update many list items in-place.
          asyncViewProps.state === "success" &&
          (!allowCallToSignUp || callToSignUpState === "hidden")
            ? {
                ref: listView,
                // Re-mount the list when switching tabs to avoid re-using views
                // (which results in ugly jitters)
                key: `library-${tab}`,
                items: listViewItems,
                renderItem: (item) =>
                  item.type === "libraryItem" ? (
                    <LibraryListItem
                      isOffline={offline}
                      onPressRemove={
                        tab !== "purchased" ? onPressRemoveItem : undefined
                      }
                      onPressMarkFinished={
                        tab !== "finished" ? onPressMarkItemFinished : undefined
                      }
                      libraryItem={item.libraryItem}
                    />
                  ) : item.type === "offlineMessage" ? (
                    <LibraryOfflineMessage height={offlineMessageHeight} />
                  ) : item.type === "emptyMessage" ? (
                    <LibraryEmptyMessage
                      tab={tab}
                      height={emptyMessageHeight}
                    />
                  ) : null,
                getItemKey: (item, index) =>
                  `${tab}-${item.type}-${
                    item.type === "libraryItem"
                      ? getLibraryItemKey(item.libraryItem)
                      : item.type === "spacer"
                        ? `${index}-${item.size}`
                        : index
                  }`,
                getItemSize: (item) =>
                  item.type === "libraryItem"
                    ? libraryItemHeight
                    : item.type === "spacer"
                      ? item.size
                      : item.type === "offlineMessage"
                        ? offlineMessageHeight
                        : item.type === "emptyMessage"
                          ? emptyMessageHeight
                          : 0,
              }
            : undefined
        }
      >
        {allowCallToSignUp ? (
          <CallToSignUp
            onStateChange={setCallToSignUpState}
            showLogo={tokens.appHeader.mode !== "desktop"}
            paddingTop={headerHeight}
            paddingHorizontal="pagePaddingHorizontal"
            paddingBottom="large"
          />
        ) : null}
      </PageLayout>
    </AsyncViewOfflineProvider>
  )
}
