import React, { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"

import styled from "styled-components/native"

import { StripeProrationAvailableResponse } from "@treefort/api-spec"
import { useAuth } from "@treefort/lib/auth-provider"
import { formatMoney } from "@treefort/lib/money"
import { simpleHash } from "@treefort/lib/simple-hash"

import AsyncView, { AsyncViewProps } from "../../../components/async-view"
import {
  BillingIntervalToggle,
  BillingInterval,
} from "../../../components/billing-interval-toggle"
import {
  CheckoutCodeInput,
  CheckoutCodeInputState,
} from "../../../components/checkout-code-input"
import Column from "../../../components/column"
import ImageContained from "../../../components/image-contained"
import { RestorePurchasesLink } from "../../../components/restore-purchases-link"
import {
  SignUpOptionCard,
  SIGN_UP_CARD_MAX_WIDTH_PX,
} from "../../../components/sign-up-option-card"
import Spacer from "../../../components/spacer"
import StripeProrationPreviewModal from "../../../components/stripe-proration-preview-modal"
import { useTenantLogo } from "../../../components/tenant-logo"
import Text from "../../../components/text"
import { useTokens } from "../../../components/tokens-provider"
import { useUserSubscriptions } from "../../../hooks/subscriptions"
import { useAsyncViewPropsForQueries } from "../../../hooks/use-async-view-props-for-queries"
import { useMenu } from "../../../hooks/use-menu"
import { useNavigate } from "../../../hooks/use-navigate"
import { useOpenLibrary } from "../../../hooks/use-open-library"
import { useRoute } from "../../../hooks/use-route"
import useSafeAreaInsetAppHeader from "../../../hooks/use-safe-area-inset-app-header"
import { useSignUpOptionsData } from "../../../hooks/use-sign-up-options-data"
import authenticator from "../../../lib/authenticator"
import {
  CheckoutCodeValidationResult,
  getCheckoutCodeValidationMessage,
  validateCheckoutCode,
} from "../../../lib/checkout-codes"
import { checkoutSessionManager, Event } from "../../../lib/checkout/session"
import { unsetQueryParam } from "../../../lib/location"
import { logError } from "../../../lib/logging"
import {
  SignUpOption,
  getBillingIntervalsToShow,
  getSignUpOptions,
} from "../../../lib/sign-up-options"
import { formatPlanIntervalShort } from "../../../lib/subscription-plans"
import {
  getCurrentSubscription,
  subscriptionWillRenew,
} from "../../../lib/subscriptions"
import MenuLayout from "../../layouts/menu"
import PageLayout from "../../layouts/page"
import { SplashScreen } from "../splash"

const CARD_ROW_GAP_PX = 24
const CARD_COLUMN_GAP_PX = 36
const CALL_TO_SUBSCRIBE_TEXT_MAX_WIDTH_PX = 480
const TENANT_LOGO_MAX_HEIGHT_PX = 64
const TENANT_LOGO_MAX_WIDTH_PX = 94
const SIGN_UP_CODE_INPUT_DEBOUNCE_MS = 2000
const PASSTHROUGH_ROUTE_PARAM = "passthrough"

const includeCodeInput = Platform.OS === "web"
const includeStripeProrationPreviewModal = Platform.OS === "web"

type CheckoutCodeState = {
  inputState: CheckoutCodeInputState
  validationResult?: CheckoutCodeValidationResult
}

/**
 * Get the number of cards per row that will a) fit on the screen and b) allow
 * the cards to wrap in a balanced way such that all rows will either have the
 * same number of cards or the last row will have one less card than the other
 * rows.
 */
function getCardsPerRow({
  displayWidth,
  cardCount,
}: {
  displayWidth: number
  cardCount: number
}) {
  const maxCardsPerRow = Math.floor(
    (displayWidth - CARD_ROW_GAP_PX) /
      (SIGN_UP_CARD_MAX_WIDTH_PX + CARD_ROW_GAP_PX),
  )
  for (let cardsPerRow = maxCardsPerRow; cardsPerRow > 0; cardsPerRow--) {
    const leftoverCards = cardCount > cardsPerRow ? cardCount % cardsPerRow : 0
    if (leftoverCards === 0 || cardsPerRow - leftoverCards === 1) {
      return cardsPerRow
    }
  }
  return maxCardsPerRow
}

function getCardKey(option: SignUpOption) {
  return `${option.title}${option.terms ? simpleHash(option.terms) : ""}`
}

const CardRow = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  padding: ${({ theme }) => `0 ${theme.spacing.small}px`};
  width: 100%;
  gap: ${CARD_ROW_GAP_PX}px;
`

const CardColumn = styled.View`
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${CARD_COLUMN_GAP_PX}px;
`

const PaddingContainer = styled(Column)<{ layout: "wide" | "narrow" }>`
  padding-horizontal: ${({ theme, layout }) =>
    theme.checkoutScreen.container.paddingHorizontal[layout]}px;
  padding-bottom: ${({ theme }) =>
    theme.appHeader.mode === "desktop" ? theme.spacing.jumbo + "px" : 0};
`

function Layout({
  layout,
  children,
  ...asyncViewProps
}: {
  layout: "wide" | "narrow"
  children: React.ReactNode
} & AsyncViewProps) {
  const { tokens, displayWidth } = useTokens()
  const appHeaderSafeAreaInset = useSafeAreaInsetAppHeader()
  const desktop =
    layout === "wide" &&
    displayWidth >= tokens.breakpoints.desktop &&
    Platform.OS === "web"
  const backgroundColor = tokens.colors.background.tertiary
  return desktop ? (
    <PageLayout
      backgroundColor={backgroundColor}
      headerBackgroundColor={backgroundColor}
    >
      <AsyncView backgroundColor={backgroundColor} {...asyncViewProps}>
        <Spacer size={appHeaderSafeAreaInset} />
        {children}
        <Spacer size="large" />
      </AsyncView>
    </PageLayout>
  ) : (
    <MenuLayout
      paddingHorizontal={0}
      backgroundColor={backgroundColor}
      asyncViewProps={asyncViewProps}
    >
      {children}
    </MenuLayout>
  )
}

function CallToSubscribe({
  layout,
  includeText,
  onReady,
}: {
  layout: "wide" | "narrow"
  includeText?: boolean
  onReady: (ready: boolean) => void
}) {
  const { tokens, displayMode } = useTokens()
  const tenantLogo = useTenantLogo()
  const { t } = useTranslation()

  useEffect(() => {
    if (tokens.appHeader.mode === "desktop") {
      onReady(true)
    }
  }, [onReady, tokens.appHeader.mode])

  return (
    <Column
      alignItems="center"
      maxWidth={CALL_TO_SUBSCRIBE_TEXT_MAX_WIDTH_PX}
      paddingBottom="none"
      gap={layout === "wide" ? "large" : "medium"}
    >
      {tokens.appHeader.mode === "mobile" ? (
        <ImageContained
          containerSize={{
            width: TENANT_LOGO_MAX_WIDTH_PX,
            height: TENANT_LOGO_MAX_HEIGHT_PX,
          }}
          uri={tenantLogo[displayMode]}
          onReady={onReady}
        />
      ) : null}
      {includeText ? (
        <Text textStyle="headingMedium" alignment="center">
          {t(
            "Subscribe to get unlimited access to the full catalogue of content.",
          )}
        </Text>
      ) : null}
    </Column>
  )
}

export default function CheckoutScreen(): JSX.Element {
  const { t, i18n } = useTranslation()
  const route = useRoute()
  const auth = useAuth()
  const userSubscriptions = useUserSubscriptions()
  const subscription = getCurrentSubscription(userSubscriptions?.data)
  const [callToSubscribe, setCallToSubscribeReady] = useState(false)
  const { displayWidth } = useTokens()
  const [codeInputValue, setCodeInputValue] = useState(
    route.params.promoCode || route.params.membershipCode || "",
  )
  const [checkoutCodeState, setCheckoutCodeState] = useState<CheckoutCodeState>(
    { inputState: { type: "idle" } },
  )
  const [loadingRouteCheckoutCode, setLoadingRouteCheckoutCode] = useState(
    Boolean(route.params.promoCode || route.params.membershipCode),
  )
  const [userSelectedBillingInterval, setUserSelectedBillingInterval] =
    useState<BillingInterval>()

  // If the user is switching Stripe subscription plans then we show them a modal
  // previewing the changes
  const [stripePreviewModalState, setStripePreviewModalState] = useState<
    | { open: false }
    | {
        open: true
        action: () => Promise<void>
        preview: StripeProrationAvailableResponse
        title: string
      }
  >({ open: false })

  // If the screen is only wide enough for a single card then we're in the
  // narrow layout, otherwise we're in the wide layout
  const layout =
    displayWidth >=
    (SIGN_UP_CARD_MAX_WIDTH_PX + CARD_ROW_GAP_PX) * 2 + CARD_ROW_GAP_PX
      ? "wide"
      : "narrow"

  // Close the menu when a checkout session is complete
  const openLibrary = useOpenLibrary()
  const menu = useMenu()
  const navigate = useNavigate()
  useEffect(() => {
    return checkoutSessionManager.on(
      Event.CheckoutSessionEnded,
      ({ complete, session }) => {
        if (complete) {
          // If a coupon was redeemed then head over to the library purchases
          // tab, otherwise just close the menu (in which case a user who
          // initiated the checkout flow from a piece of content will be sent
          // back to that content)
          if (session.type === "coupon" && openLibrary) {
            openLibrary("purchased")
          } else {
            menu.close()
          }
        }
      },
    )
  }, [openLibrary, menu, navigate])

  /**
   * SIGN UP OPTIONS
   *
   * Determine which cards to show on the page based on the platform, the user,
   * the offeringIds param, and a host of other factors.
   */

  // If the user is upgrading or subscribing to gain access to a particular
  // peice of content or feature then the offeringIds query param is set
  const offeringIds = useMemo(() => {
    return Array.isArray(route.params.offeringIds)
      ? route.params.offeringIds.flatMap((id) =>
          !isNaN(parseInt(id)) ? parseInt(id) : [],
        )
      : route.params.offeringIds && !isNaN(parseInt(route.params.offeringIds))
        ? [parseInt(route.params.offeringIds)]
        : undefined
  }, [route.params.offeringIds])

  const signUpOptionsData = useSignUpOptionsData({
    offeringIds: offeringIds?.length ? offeringIds : undefined,
    checkoutCodeValidationResult: checkoutCodeState.validationResult,
  })

  // If the user is subscribing to gain access to a particular peice of content
  // then the checkoutContentId query param should be set
  const contentId =
    route.params.checkoutContentId &&
    !isNaN(parseInt(route.params.checkoutContentId))
      ? parseInt(route.params.checkoutContentId)
      : undefined

  const recommId = route.params.recommId
  const highlightSubscriptionPlanId =
    route.params.plan && !isNaN(parseInt(route.params.plan))
      ? parseInt(route.params.plan)
      : undefined
  const currentSubscriptionPlan =
    subscription && !subscription.deactivatedAt
      ? signUpOptionsData.data?.availableSubscriptionPlans.find(
          (plan) => plan.id === subscription.subscriptionPlanId,
        )
      : undefined
  const billingInterval = userSelectedBillingInterval
    ? userSelectedBillingInterval
    : signUpOptionsData.data
      ? getBillingIntervalsToShow({
          signUpOptionsData: signUpOptionsData.data,
          highlightSubscriptionPlanId,
          currentSubscriptionPlan,
        })
      : undefined

  const optionsReady = signUpOptionsData.data && billingInterval
  const options = useMemo(() => {
    if (optionsReady) {
      const options = getSignUpOptions({
        billingInterval,
        promoCode: codeInputValue,
        setStripePreviewModalState,
        signUpOptionsData: signUpOptionsData.data,
        highlightSubscriptionPlanId,
        authenticated: Boolean(auth.user),
        subscription,
        checkoutCodeValidationResult: checkoutCodeState.validationResult,
        isUpgrading: Boolean(
          offeringIds?.length && subscriptionWillRenew(subscription),
        ),
        contentId,
        recommId,
      })

      // Sort the user's current plan to the end on mobile to get it out of the
      // way when upgrading, changing plans, etc.
      if (layout === "narrow") {
        options.sort((a, b) =>
          a.current && !b.current ? 1 : !a.current && b.current ? -1 : 0,
        )
      }

      return options
    } else {
      return []
    }
  }, [
    layout,
    optionsReady,
    signUpOptionsData.data,
    billingInterval,
    codeInputValue,
    highlightSubscriptionPlanId,
    auth,
    subscription,
    checkoutCodeState.validationResult,
    offeringIds,
    contentId,
    recommId,
  ])

  const optionsIncludePaidPlan = options.some(
    (option) => option.terms !== undefined,
  )

  /**
   * CHECKOUT CODES
   *
   * Handle promo codes, coupon codes, or group membership codes provided via
   * the code input or via a route param.
   */

  // Track the current subscription plan in a ref so that it can be accessed in
  // the promo code input effect without re-running the effect when it changes
  const currentSubscriptionPlanRef = useRef(currentSubscriptionPlan)
  useEffect(() => {
    currentSubscriptionPlanRef.current = currentSubscriptionPlan
  }, [currentSubscriptionPlan])

  // Debounce requests to check if the input value is a valid promo code or group
  // membership code and set the input state based on the results
  const timeout = useRef<NodeJS.Timeout>()
  const codeInputValueRef = useRef("")
  useEffect(() => {
    if (
      codeInputValueRef.current !== codeInputValue.trim() &&
      // Do nothing if signUpOptionsData hasn't loaded yet since we need to know
      // the list of plans to validate the promo code for
      signUpOptionsData.data &&
      // Checkout codes are only supported on the web at the moment
      Platform.OS === "web"
    ) {
      clearTimeout(timeout.current)
      const trimmedCode = codeInputValue.trim()

      // Reset promo code related state whenever the effect runs
      setCheckoutCodeState({
        inputState: { type: trimmedCode ? "loading" : "idle" },
      })

      // Track the input value in a ref so we have a simple way to tell if
      // promo code or group membership code validation requests are stale
      codeInputValueRef.current = trimmedCode

      if (trimmedCode) {
        timeout.current = setTimeout(async () => {
          try {
            // Clear any outstanding checkout session when the user is
            // messing around in the code input
            checkoutSessionManager.endSession({ complete: false })

            const validationResult = await validateCheckoutCode({
              code: trimmedCode,
              subscriptionPlanIds:
                signUpOptionsData.data.recommendedSubscriptionPlans.map(
                  (plan) => plan.id,
                ),
            })

            // Bail if the input changed after we requested validation for this
            // code
            if (trimmedCode !== codeInputValueRef.current) {
              return
            }

            switch (validationResult?.type) {
              case "validCouponCode":
              case "validGroupMembershipCode":
              case "validStripePromoCode":
              case "validWebPaymentPromoCode": {
                setCheckoutCodeState({
                  inputState: {
                    type: "success",
                    message: getCheckoutCodeValidationMessage({
                      i18n,
                      validationResult,
                    }),
                  },
                  validationResult,
                })
                // Reset the billing interval in case filtering by
                // eligibleSubscriptionPlanIds changes which interval
                // can/should be shown
                setUserSelectedBillingInterval(undefined)
                break
              }
              case "invalidGroupMembershipCode":
              case "invalidCouponCode":
              case "invalidCode": {
                setCheckoutCodeState({
                  inputState: {
                    type: "error",
                    message:
                      getCheckoutCodeValidationMessage({
                        i18n,
                        validationResult,
                      }) || t("Invalid code."),
                  },
                })
                break
              }
              default: {
                validationResult satisfies never
                throw new Error("Invalid validation result (ha)")
              }
            }
          } catch (cause) {
            logError(
              new Error("[Checkout] Failed to validate checkout code", {
                cause,
              }),
            )
            setCheckoutCodeState({
              inputState: {
                type: "error",
                message: t("An error occurred. Please try again."),
              },
            })
          } finally {
            setLoadingRouteCheckoutCode(false)
          }
        }, SIGN_UP_CODE_INPUT_DEBOUNCE_MS)
      }
    }
  }, [codeInputValue, signUpOptionsData.data, i18n, t])

  /**
   * PASSTHROUGH
   *
   * If the route contains a "passthrough" parameter then trigger the
   * highlighted option's checkout action without presenting our checkout page
   * at all. This is helpful when tenants want to create their own checkout
   * experience.
   */

  const [passthrough, setPassthrough] = useState(
    route.params[PASSTHROUGH_ROUTE_PARAM] !== undefined,
  )
  const passthroughOption = passthrough
    ? options.find((option) => option.highlighted)
    : undefined

  useEffect(() => {
    if (!passthrough) {
      return
    }

    // If passthrough is set and we found a sign up option to pass through to
    // then go for it.
    if (passthroughOption?.button?.onPress) {
      unsetQueryParam(PASSTHROUGH_ROUTE_PARAM)
      passthroughOption.button.onPress()
    }
    // If we couldn't find a good option to pass through to then revert to the
    // normal checkout flow.
    else if (optionsReady) {
      unsetQueryParam(PASSTHROUGH_ROUTE_PARAM)
      setPassthrough(false)
    }
  }, [passthrough, optionsReady, passthroughOption])

  /**
   * RENDER
   */

  const asyncViewProps = useAsyncViewPropsForQueries(
    [userSubscriptions, signUpOptionsData],
    {
      forceLoading: !callToSubscribe || loadingRouteCheckoutCode,
    },
  )

  // Break the cards up into rows for the wide layout
  const cardRows: number[][] = []
  if (layout === "wide") {
    const cardCount = options.length
    const cardsPerRow = getCardsPerRow({ displayWidth, cardCount })
    for (let cardIndex = 0; cardIndex < cardCount; cardIndex++) {
      const rowIndex = Math.floor(cardIndex / cardsPerRow)
      cardRows[rowIndex] ||= []
      cardRows[rowIndex].push(cardIndex)
    }
  }

  // Hold the splash screen open in passthrough mode to avoid awkwardly flashing
  // the app UI before we head on to the account creation or payment flow.
  return passthrough ? (
    <SplashScreen />
  ) : (
    <>
      <Layout layout={layout} {...asyncViewProps}>
        <PaddingContainer layout={layout} gap="xlarge">
          <Column gap="large" width="100%">
            <CallToSubscribe
              layout={layout}
              onReady={setCallToSubscribeReady}
              includeText={optionsIncludePaidPlan}
            />
            {optionsIncludePaidPlan &&
            billingInterval &&
            billingInterval !== "all" ? (
              <BillingIntervalToggle
                value={billingInterval}
                onChange={setUserSelectedBillingInterval}
              />
            ) : null}
            {includeCodeInput ? (
              <CheckoutCodeInput
                layout={layout}
                state={checkoutCodeState.inputState}
                value={codeInputValue}
                onChange={setCodeInputValue}
                autoFocus={"focusCodeInput" in route.params}
              />
            ) : null}
          </Column>
          {layout === "wide" ? (
            cardRows.map((row, rowIndex) => (
              <CardRow key={rowIndex}>
                {row.map((cardIndex) => {
                  const option = options[cardIndex]
                  return (
                    <SignUpOptionCard
                      key={getCardKey(option)}
                      layout={layout}
                      option={option}
                    />
                  )
                })}
              </CardRow>
            ))
          ) : (
            <CardColumn>
              {options.map((option) => (
                <SignUpOptionCard
                  key={getCardKey(option)}
                  layout={layout}
                  option={option}
                />
              ))}
            </CardColumn>
          )}
          <Column gap="medium">
            {currentSubscriptionPlan ? (
              <Text textStyle="body" color="secondary">
                {t("Current plan: {{planName}}", {
                  planName: [
                    currentSubscriptionPlan.name,
                    currentSubscriptionPlan.provider !== "groupMembership"
                      ? formatMoney(
                          currentSubscriptionPlan.price,
                          i18n.language,
                        ) + formatPlanIntervalShort(currentSubscriptionPlan)
                      : undefined,
                  ]
                    .filter(Boolean)
                    .join(" "),
                  interpolation: { escapeValue: false },
                })}
              </Text>
            ) : !auth.user ? (
              <Text textStyle="body" alignment="center" color="secondary">
                {t("Already have an account?")}{" "}
                <Text
                  textStyle="body"
                  color="accent"
                  onPress={() => authenticator.login()}
                  role="link"
                  aria-label={t("Sign In")}
                >
                  {t("Sign In")}
                </Text>
              </Text>
            ) : null}
            <RestorePurchasesLink />
          </Column>
        </PaddingContainer>
      </Layout>
      {includeStripeProrationPreviewModal ? (
        <StripeProrationPreviewModal
          preview={
            stripePreviewModalState.open
              ? stripePreviewModalState.preview
              : undefined
          }
          open={stripePreviewModalState.open}
          onClose={() => setStripePreviewModalState({ open: false })}
          action={
            stripePreviewModalState.open
              ? stripePreviewModalState.action
              : undefined
          }
        />
      ) : null}
    </>
  )
}
