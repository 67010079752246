import i18next from "i18next"

import { refetchUserSubscriptions } from "../../../hooks/subscriptions"
import api from "../../api"
import { redeemCouponCode } from "../../coupons"
import { toast } from "../../toaster"
import { requestGroupMembership } from "../request-group-membership"
import { requestPayment } from "../request-payment"
import { CheckoutSession, checkoutSessionManager } from "../session"

export async function completeCheckout(checkoutSession: CheckoutSession) {
  switch (checkoutSession.type) {
    case "paidPlan":
      await requestPayment({ checkoutSession })
      break

    case "paidPlanResubscription":
      if (checkoutSession.plan.provider === "webPayment") {
        await requestPayment({ checkoutSession })
      } else {
        await api.patch("/integrations/stripe/subscription", {
          type: "resubscription",
          subscriptionPlanId: checkoutSession.plan.id,
          promoCode: checkoutSession.promoCode,
        })
      }
      break

    case "paidPlanWithProrationPreview":
      await api.patch("/integrations/stripe/subscription", {
        type: "proration",
        subscriptionPlanId: checkoutSession.plan.id,
        prorationDate: checkoutSession.prorationDate,
        stripeSubscriptionId: checkoutSession.stripeSubscriptionId,
        promoCode: checkoutSession.promoCode,
      })
      break

    case "groupMembership":
      await requestGroupMembership({ checkoutSession })
      break

    case "coupon": {
      await redeemCouponCode({ couponCodeId: checkoutSession.couponCodeId })
      checkoutSessionManager.endSession({ complete: true })
      toast.success(i18next.t("Your code has been redeemed. Thank you!"))
      break
    }

    default:
      checkoutSession satisfies never
  }

  // Refetch the user's subscriptions for subscription-based checkout sessions.
  // This will both refresh the app's UI with the user's new access and will end
  // up triggering handleSubscriptionResponseForCheckout which will close out
  // the session and pop a success toast (assuming everything worked correctly).
  switch (checkoutSession.type) {
    case "paidPlan":
    case "paidPlanResubscription":
    case "paidPlanWithProrationPreview":
    case "groupMembership":
      await refetchUserSubscriptions()
      break
  }
}
