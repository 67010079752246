import { EventEmitter } from "@treefort/lib/event-emitter"
import { simpleHash } from "@treefort/lib/simple-hash"

import { logError } from "../logging"
import { Store } from "../store"
import { AvailableSubscriptionPlan } from "../subscription-plans/get-available-subscription-plans/base"

type PaidPlan = Extract<
  AvailableSubscriptionPlan,
  {
    provider: Exclude<AvailableSubscriptionPlan["provider"], "groupMembership">
  }
>

type CommonParams = {
  /**
   * The content that triggered the checkout flow (e.g. if the user taps
   * "Subscribe to watch" on a specific video then that video's id will
   * be set there)
   */
  contentId?: number
  /**
   * The recommendation that triggered the checkout flow (e.g. if the user
   * tapped on a recommended video and subscribed then that recommendation's id
   * will be set here)
   */
  recommId?: string
}

export type CheckoutSessionPaidPlan = CommonParams & {
  type: "paidPlan"
  plan: PaidPlan
  isFreeTrial: boolean
  promoCode?: string
}

export type CheckoutSessionPaidPlanResubscription = CommonParams & {
  type: "paidPlanResubscription"
  plan: PaidPlan
  promoCode?: string
}

export type CheckoutSessionPaidPlanWithProrationPreview = CommonParams & {
  type: "paidPlanWithProrationPreview"
  plan: PaidPlan
  stripeSubscriptionId: string
  prorationDate: Date
  promoCode?: string
}

export type CheckoutSessionGroupMembership = CommonParams & {
  type: "groupMembership"
  membershipCode: string
  subscriptionPlanId: number
}

export type CheckoutSessionCoupon = CommonParams & {
  type: "coupon"
  couponCode: string
  couponCodeId: number
}

export type CheckoutSession =
  | CheckoutSessionPaidPlan
  | CheckoutSessionPaidPlanResubscription
  | CheckoutSessionPaidPlanWithProrationPreview
  | CheckoutSessionGroupMembership
  | CheckoutSessionCoupon

export enum Event {
  CheckoutSessionStarted = "CHECKOUT_SESSION_STARTED",
  CheckoutSessionChanged = "CHECKOUT_SESSION_CHANGED",
  CheckoutSessionEnded = "CHECKOUT_SESSION_ENDED",
}

interface EventMap {
  [Event.CheckoutSessionStarted]: CheckoutSession
  [Event.CheckoutSessionChanged]: CheckoutSession | null
  [Event.CheckoutSessionEnded]: {
    session: CheckoutSession
    complete: boolean
  }
}

/**
 * The checkout session manager is used to keep track of whether the user is in
 * the process of checking out a subscription (or free account).
 */
export class CheckoutSessionManager extends EventEmitter<EventMap> {
  private store: Store
  private session: CheckoutSession | null | undefined
  private readonly initialized: Promise<void>

  constructor() {
    super()
    this.store = new Store({ key: "checkoutSessionManager" })
    this.initialized = this.store
      .get<CheckoutSession>("session")
      .then(this.setSession)
      .catch(logError)
  }

  private setSession = (session: CheckoutSession | null): void => {
    if (simpleHash(session) !== simpleHash(this.session)) {
      this.session = session
      this.store.set("session", this.session).catch(logError)
      this.emitter.emit(Event.CheckoutSessionChanged, this.session)
    }
  }

  startSession = async (session: CheckoutSession): Promise<CheckoutSession> => {
    await this.initialized
    this.setSession(session)
    this.emitter.emit(Event.CheckoutSessionStarted, session)
    return session
  }

  endSession = async ({ complete }: { complete: boolean }): Promise<void> => {
    await this.initialized
    const session = this.session
    if (session) {
      this.setSession(null)
      this.emitter.emit(Event.CheckoutSessionEnded, { session, complete })
    }
  }

  getSession = async (): Promise<CheckoutSession | null | undefined> => {
    await this.initialized
    return this.session
  }

  getSessionSync = (): CheckoutSession | null | undefined => this.session
}

export const checkoutSessionManager = new CheckoutSessionManager()
