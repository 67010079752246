import React, { useMemo, useState, useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import styled from "styled-components/native"

import { joinContributorNames } from "@treefort/lib/contributor"
import icons from "@treefort/tokens/app/icons"

import { useActiveProfileId } from "../../hooks/use-active-profile-id"
import useAppManifest from "../../hooks/use-app-manifest"
import { useAsyncViewPropsForQueries } from "../../hooks/use-async-view-props-for-queries"
import { useBooleanState } from "../../hooks/use-boolean-state"
import useContent from "../../hooks/use-content"
import { useOpenCheckoutPage } from "../../hooks/use-open-checkout-page"
import { useProgressItem } from "../../hooks/use-progress-item"
import {
  canDownloadConsumableContent,
  getConsumableContentFromAudiobookResponse,
  getTracksFromConsumableContent,
  AudiobookConsumableContent,
} from "../../lib/consumable-content"
import { playContentAudio } from "../../lib/content-audio"
import { formatDate } from "../../lib/date"
import { syncManager } from "../../watermelon/sync"
import AddToLibraryButton, { canAddToLibrary } from "../add-to-library-button"
import { AsyncButton } from "../async-button"
import { useAsyncViewProps } from "../async-view"
import { BoxPadding } from "../box"
import DownloadButton from "../download-button"
import { Heading } from "../heading"
import IconButton from "../icon-button"
import LockedContentButton from "../locked-content-button"
import { MetadataSpacer, MetadataText } from "../metadata"
import ModuleArtworkLayout from "../module-artwork-layout"
import ProgressForConsumableContent from "../progress-for-consumable-content"
import Row from "../row"
import Select from "../select"
import Spacer from "../spacer"
import TextToggle from "../text-toggle"

const { buttonFlexContainerStyle } = StyleSheet.create({
  buttonFlexContainerStyle: { flex: 1 },
})

const PlayButton = styled(AsyncButton)`
  flex: ${(props) =>
    props.theme.audiobookModule.playButtonFlex ? "1" : "none"};
`

const LockedPlayButton = styled(LockedContentButton)`
  flex: ${(props) =>
    props.theme.audiobookModule.playButtonFlex ? "1" : "none"};
`

function AudiobookTrackSelect({
  consumableContent,
  ...selectProps
}: Omit<Parameters<typeof Select>[0], "value" | "options" | "onChange"> & {
  consumableContent?: AudiobookConsumableContent
  options: Array<{ label: string; value: number }>
  onChange?: (value: number) => void
}) {
  const progressItem = useProgressItem({
    consumableContent,
    shouldUpdate: (a, b) => a?.progress?.index !== b?.progress?.index,
  })
  return <Select {...selectProps} value={progressItem?.getProgress().index} />
}

export function AudiobookModule({
  contentId,
  maxWidth,
  paddingTop = "medium",
  presentation = "solo",
}: {
  contentId: number
  maxWidth?: number
  paddingTop?: BoxPadding
  presentation?: "solo" | "listItem"
}): JSX.Element {
  const { t, i18n } = useTranslation()
  const profileId = useActiveProfileId()
  const [artworkReady, setArtworkReady] = useState(false)
  const [trackSelectOpen, openTrackSelect, closeTrackSelect] =
    useBooleanState(false)
  const audiobook = useContent(contentId, "book")
  const firstChapter = audiobook.data?.details.chapters[0]
  const isLocked = firstChapter?.audioMedia?.status === "notAvailable"
  const consumableContent = audiobook.isSuccess
    ? getConsumableContentFromAudiobookResponse(audiobook.data)
    : undefined

  useEffect(() => {
    syncManager.requestSync({ syncType: "user-initiated" })
  }, [])

  const tracks = useMemo(
    () => getTracksFromConsumableContent({ consumableContent, profileId }),
    [consumableContent, profileId],
  )
  const trackCount = tracks.length
  const manifest = useAppManifest()
  const showDownloadButton =
    trackCount &&
    consumableContent &&
    canDownloadConsumableContent(consumableContent)
  const showAddToLibraryButton = canAddToLibrary({
    manifest,
    consumableContent,
  })
  const includeTrackSelect = trackCount > 1

  const openCheckoutPage = useOpenCheckoutPage({
    availability: firstChapter?.audioMedia,
    contentId,
  })

  const playAudiobook = useCallback(
    (trackIndex?: number) => {
      closeTrackSelect()
      if (consumableContent) {
        return playContentAudio({ consumableContent, profileId, trackIndex })
      }
    },
    [consumableContent, profileId, closeTrackSelect],
  )

  useAsyncViewProps(
    useAsyncViewPropsForQueries([audiobook], {
      forceLoading: audiobook.data?.artworkMedia ? !artworkReady : false,
    }),
  )

  const author = joinContributorNames(audiobook.data?.contributors, "author")
  const publisher = joinContributorNames(
    audiobook.data?.contributors,
    "publisher",
  )

  return (
    <ModuleArtworkLayout
      paddingTop={paddingTop}
      artwork={audiobook.data?.artworkMedia?.original.url}
      onArtworkReady={setArtworkReady}
      maxWidth={maxWidth}
    >
      {({ layout }) => (
        <>
          <Heading
            level={1}
            textStyle="headingLarge"
            color="primary"
            maxWidth="title"
          >
            {audiobook.data?.title || ""}
          </Heading>
          <Spacer size="small" />
          <Row>
            {author ? <MetadataText>{author}</MetadataText> : null}
            {author && audiobook.data?.publishedAt ? <MetadataSpacer /> : null}
            {audiobook.data?.publishedAt ? (
              <MetadataText>
                {formatDate(new Date(audiobook.data.publishedAt), {
                  strategy: "fullDate",
                  i18n,
                })}
              </MetadataText>
            ) : null}
            <ProgressForConsumableContent
              consumableContent={consumableContent}
              includeProgressLabel
              includeDurationLabel
              includeFinishedBadge
              childrenBefore={
                audiobook.data?.publishedAt ? <MetadataSpacer /> : undefined
              }
            />
          </Row>
          {publisher ? <MetadataText>{publisher}</MetadataText> : null}
          <ProgressForConsumableContent
            consumableContent={consumableContent}
            includeProgressBar
            childrenBefore={<Spacer size="small" />}
          />
          <Spacer size="medium" />
          <Row>
            {firstChapter?.audioMedia?.status === "notAvailable" ? (
              <LockedPlayButton
                openCheckoutPage={openCheckoutPage}
                availability={firstChapter.audioMedia}
                action="listen"
                containerStyle={buttonFlexContainerStyle}
                externalPurchaseUrl={audiobook.data?.externalPurchaseUrl}
              />
            ) : (
              <PlayButton
                icon={icons.headphones}
                type="primary"
                onPress={playAudiobook}
                disabled={!audiobook.isSuccess}
                containerStyle={buttonFlexContainerStyle}
              >
                {t("Listen")}
              </PlayButton>
            )}
            {includeTrackSelect ? (
              <>
                <Spacer size="large" horizontal />
                <IconButton
                  source={icons.menuStaggered}
                  onPress={openTrackSelect}
                  label={t("Table of Contents")}
                  color="secondary"
                  minSize="buttonHeight"
                />
              </>
            ) : null}
            {showDownloadButton ? (
              <>
                <Spacer
                  size={includeTrackSelect ? "small" : "large"}
                  horizontal
                />
                <DownloadButton consumableContent={consumableContent} />
              </>
            ) : null}
            {showAddToLibraryButton ? (
              <>
                <Spacer
                  size={
                    showDownloadButton || includeTrackSelect ? "small" : "large"
                  }
                  horizontal
                />
                <AddToLibraryButton consumableContent={consumableContent} />
              </>
            ) : null}
          </Row>
          {audiobook.data?.description ? (
            <>
              <Spacer size="medium" />
              <TextToggle
                textStyle="body"
                numberOfLines={
                  presentation === "listItem" && layout === "portrait" ? 2 : 6
                }
                maxWidth="description"
                withLinks
              >
                {audiobook.data.description}
              </TextToggle>
            </>
          ) : null}
          {includeTrackSelect ? (
            <AudiobookTrackSelect
              label={tracks[0].album || "Table of Contents"}
              open={trackSelectOpen}
              onChange={isLocked ? undefined : playAudiobook}
              onClose={closeTrackSelect}
              options={tracks.map((track, index) => ({
                label: track.title,
                value: index,
              }))}
              consumableContent={consumableContent}
            />
          ) : null}
        </>
      )}
    </ModuleArtworkLayout>
  )
}
